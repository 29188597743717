
<script>
export default {
  name: "Confetti",
  props: {
    COLORS: {
      type: Array,
      default: () => [
        [251, 238, 152], 
        [234, 200, 104], 
        [251, 242, 161],
        [190, 135, 51],
        [211, 167, 76]
      ]
    }
  },
  data() {
    return {
      NUM_CONFETTI: 40,
      PI_2: 2 * Math.PI,
      confetti: [],
      xpos: 0.5,
      context: null,
    };
  },
  methods: {
    resizeCanvas() {
      const container = this.$refs.container;
      const canvas = this.$refs.confettiCanvas;

      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;
    },
    range(a, b) {
      const array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      const randomFraction = array[0] / (0xFFFFFFFF + 1);
      return (b - a) * randomFraction + a;
    },
    drawCircle(a, b, c, d) {
      this.context.beginPath();
      this.context.moveTo(a, b);
      this.context.bezierCurveTo(a - 17, b + 14, a + 13, b + 5, a - 5, b + 22);
      this.context.lineWidth = 2;
      this.context.strokeStyle = d;
      this.context.stroke();
    },
    drawCircle2(a, b, c, d) {
      this.context.beginPath();
      this.context.moveTo(a, b);
      this.context.lineTo(a + 6, b + 9);
      this.context.lineTo(a + 12, b);
      this.context.lineTo(a + 6, b - 9);
      this.context.closePath();
      this.context.fillStyle = d;
      this.context.fill();
    },
    drawCircle3(a, b, c, d) {
      this.context.beginPath();
      this.context.moveTo(a, b);
      this.context.lineTo(a + 5, b + 5);
      this.context.lineTo(a + 10, b);
      this.context.lineTo(a + 5, b - 5);
      this.context.closePath();
      this.context.fillStyle = d;
      this.context.fill();
    },
    createConfetti() {
      const Confetti = function (range, drawCircle, drawCircle2, drawCircle3, COLORS, canvasWidth, xpos) {
        this.style = COLORS[~~range(0, 5)];
        this.rgb = "rgba(" + this.style[0] + "," + this.style[1] + "," + this.style[2];
        this.r = ~~range(2, 6);
        this.r2 = 2 * this.r;
        this.replace = function () {
          this.opacity = 0;
          this.dop = 0.03 * range(1, 4);
          this.x = range(-this.r2, canvasWidth - this.r2);
          this.y = range(-20, window.innerHeight - this.r2);
          this.xmax = canvasWidth - this.r;
          this.ymax = window.innerHeight - this.r;
          this.vx = range(0, 2) + 8 * xpos - 5;
          this.vy = 0.7 * this.r + range(-1, 1);
        };
        this.replace();
        this.draw = function () {
          this.x += this.vx;
          this.y += this.vy;
          this.opacity += this.dop;
          if (this.opacity > 1) {
            this.opacity = 1;
            this.dop *= -1;
          }
          if (this.opacity < 0 || this.y > this.ymax) {
            this.replace();
          }
          if (this.x < 0 || this.x > this.xmax) {
            this.x = (this.x + this.xmax) % this.xmax;
          }
          drawCircle(this.x, this.y, this.r, this.rgb + "," + this.opacity + ")");
          drawCircle3(0.5 * this.x, this.y, this.r, this.rgb + "," + this.opacity + ")");
          drawCircle2(1.5 * this.x, 1.5 * this.y, this.r, this.rgb + "," + this.opacity + ")");
        };
      };

      for (let i = 0; i < this.NUM_CONFETTI; i++) {
        this.confetti.push(
          new Confetti(this.range, this.drawCircle, this.drawCircle2, this.drawCircle3, this.COLORS, this.$refs.confettiCanvas.width, this.xpos)
        );
      }
    },
    animateConfetti() {
      this.context.clearRect(0, 0, this.$refs.confettiCanvas?.width || 0, this.$refs.confettiCanvas?.height || 0);
      for (const confettiPiece of this.confetti) {
        confettiPiece.draw();
      }
      requestAnimationFrame(this.animateConfetti);
    },
    handleMouseMove(e) {
      const canvas = this.$refs.confettiCanvas;
      this.xpos = e.pageX / canvas.width;
    },
  },
  mounted() {
    this.context = this.$refs.confettiCanvas.getContext("2d");
    this.resizeCanvas();
    this.createConfetti();
    this.animateConfetti();
    window.addEventListener("resize", this.resizeCanvas);
    document.addEventListener("mousemove", this.handleMouseMove);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeCanvas);
    document.removeEventListener("mousemove", this.handleMouseMove);
  },
};
</script>

<template>
  <div ref="container" class="confetti">
    <canvas ref="confettiCanvas" class="confetti__canvas"></canvas>
  </div>
</template>

<style lang="scss" scoped>
.confetti {
  position: relative;
  width: 100%;
  height: 100%;
  &__canvas {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

</style>
